.footer-container{
    background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(78, 78, 78) 100%);
    padding: 4rem 0 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top: 2.5px solid black
    
}
.footer-subscription{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-bottom: 24px;
        padding:24px;
        color: #fff;
        position: relative;
        left:10%;
        top: 20px;
}

.footer-subscription > p{
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;

}

.footer-subscription-heading{
    margin-bottom: 24px;
    font-size: 24px;
}

.footer-subscription-text{
    margin-bottom: 24px;
    font-size: 20px;
}

.footer-input{
    padding: 8px 20px;
    border-radius: 2px;
    margin-right: 10px;
    outline: none;
    border: none;
    font-size: 18px;
    margin-bottom: 16px;
    border: 1px solid white;
}
.footer-links{
    width: 100%;
    
    display: flex;
    justify-content: center;
    position: relative;
    
    top: 10px;

}

.footer-link-wrapper{
    display: flex;
    left: 15%;
}
.footer-link-items{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 16px;
    text-align:left;
    width: 160px;
    box-sizing: border-box;
}

.footer-link-items h2{
    margin-bottom: 16px;
}

.footer-link-items > h2 {
    color: #fff;

}

.footer-link-items a {
    color: #b5b5b5;
    text-decoration: none;
    margin-bottom: 8px;
}

.footer-link-items a:hover {
    color: #f6f6f6;
    transition: .3s ease-out;
}

.footer-email-form h2{
    margin-bottom: 32px;
}

.footer-input::placeholder{
    color: #848484;
}

.social-icon-link{
    color: #fff;
    font-size: 24px;
}

.social-media{
    max-width: 1000px;
    width: 100%;
}

.social-media-wrap{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: 1000px;
    margin: 40px auto 0 auto;

}

.social-icons{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 240px;

}

.social-logo{
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-self: center;
    margin-bottom: 16px;
}

.website-rights{
    color: #fff;
    margin-bottom: 16px;
}
.footer-subscription-link-container{
    display: flex;
}
.h2-footer{
    font-size: 24pt;
    position: relative;
    top:0px;
}

@media screen and (max-width: 820px) {
    .footer-links{
        scale:.95
    }

    .footer-subscription-link-container{
        display: flex;
        scale:.85;
    }

    .footer-container{
        background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(78, 78, 78) 100%);
        padding: 4rem 0 2rem 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-top: 2.5px solid black;
        width:100%
        
        
        
        
    }
    
    .footer-links{
        font-size: smaller;
        width:100%;
        position: relative;
        right:35px;
        margin-left: 80px;
        margin-top: 55px;
        
        
        
        
        
    }
    .footer-input{
        width: 100%;
    }
    .btn{
        width:100%;
    }
    .footer-link-wrapper{
        flex-direction: column;
        /* position: relative;
        right: 500px */
        
    }
    .social-media-wrap{
        flex-direction: column;
    }
    .social-logo{
        color: #fff;
        justify-self: start;
        margin-left: 20px;
        cursor: pointer;
        text-decoration: none;
        font-size: 2rem;
        display: flex;
        align-self: center;
        margin-bottom: 16px;
        position: relative;
        right: 10px;
    }

    .footer-link-items2{
        position: relative;
        right: 30px;
    }
    .footer-subscription{
        position: relative;
        left: 75px;
    }
}