* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'PT Sans', sans-serif;
  }
  h1:hover{
    color:white
  }
  
  .home,
  .services,
  .gallery,
  .about,
  .quote
   {
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
    background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(78, 78, 78) 100%);
    border: 3px solid rgb(28, 27, 27);
    
    
  }
  
  .services {
    
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: rgb(251, 211, 124);
    font-size: 80px;
  }
  
  .about {
    
    background-position: center;
    background-size: fill;
    background-repeat: no-repeat;
    color: rgb(251, 211, 124);
    font-size: 80px;
  }
  
  .quote {
    
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: rgb(251, 211, 124);
    font-size: 80px;
  }

  .gallery {
    font-size: 80px;
    color: rgb(251, 211, 124);

  }

  @media screen and (max-width: 960px){
    .quote {
      text-align: center;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      color: rgb(251, 211, 124);
      font-size: 80px;
    }


  }