video{
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
    

}

.hero-banner{
    
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0,0,0, .2);
    object-fit:contain;
    background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(78, 78, 78) 100%);
    position: relative;
    padding-bottom: 10px;
    padding-left: 0;
    margin-right: 0;
    height: 275px;
    border-bottom: 3px solid black;
    /* border-bottom: black solid 3px; */
    
    
    
    
}
.hero-banner-right{
    display: flex;
    flex-direction: column;
    align-items: center;
    
    object-fit:contain;
    
    position: relative;
    padding-bottom: 10px;
    padding-left: 0;
    margin-right: 0;
    
}
.hero-banner-left{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    font-size: 20px;
    color: rgb(233, 233, 233);
    position: relative;
    left: 40px;
}
.hero-banner-badges{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 500px;
}
.badge-mobile{
    display: none;
}
.badge:hover{
    scale:1.02;
    transition: all 300ms ease;
    filter: drop-shadow(0 0 .5em #f5a127);
}

.hero-banner-right > h1 {
    color: rgb(240, 240, 240);
    font-size: 85px;
    
}

.hero-banner-right > p{
    color: rgb(176, 176, 176);
    font-size:24px;
    margin-bottom: 9px;
}

.hero-btns{
    border: 2px solid rgb(176, 176, 176);
}
span{
    font-family: "Bebas Neue", sans-serif;
    color: #f5a127;
}
.about-gist{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    align-content: center;
    position: relative;
    width: 500px;
    text-align: center;
    background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(78, 78, 78) 100%);
    color: white;
    padding: 25px;
    border: 5px solid rgb(99, 99, 99);
    
    top: 70px;
    margin-bottom: 35px;
}


.services-gist{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    align-content: center;
    position: relative;
    width: 500px;
    text-align: center;
    background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(78, 78, 78) 100%);
    color: white;
    padding: 25px;
    border: 5px solid rgb(99, 99, 99);
    left: 20%;
    top: 70px;
    margin-bottom: 35px;
}
.testimonials-gist{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    align-content: center;
    position: relative;
    width: 500px;
    text-align: center;
    background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(78, 78, 78) 100%);
    color: white;
    padding: 25px;
    border: 5px solid rgb(99, 99, 99);
    
    top: 70px;
}
.home-logo{
    position: relative;
    width:500px;
    top: 70px;
    border: 10px solid black;
    left: 55px;
}


.home-section01{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}
.home-info{
    position: relative;
    right: 105px;
    
}

h1{
    font-family: "Bebas Neue", sans-serif;
    font-size: 56pt;
}

p{
    font-family: "Darker Grotesque", sans-serif;
    margin-bottom: 10px;
}

span{
    font-family: "Bebas Neue", sans-serif;
    font-size: 20pt;
}

.hero-btns{
    font-family: "Bebas Neue", sans-serif;
    /* color:blue */
}



@media screen and (max-width: 960px) {
    video{
        object-fit: none;
        width: 100%;
        height: 100%;
        position: fixed;
        z-index: -1;
        scale:1.2
        
    
    }
    
    .hero-banner-right{
        padding-right: 15px;
        scale:.75;
    }
    .hero-banner-badges{
        display: none;
        /* flex-direction: row;
        justify-content: space-between;
        width: 500px; */
    }
    .badge-mobile{
        display: flex;
        position: relative;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        align-content: center;
        left:10%;
        top:80px;
        height: 250px;
        scale:1.2
    }
    .badge-mobile:hover{
        scale:1.23;
        transition: all 300ms ease;
        filter: drop-shadow(0 0 .5em #f5a127);
    }
    .hero-banner-right > h1{
        color: #f5a127;
        text-align: center;
        font-size: 50px;
        /* color: rgb(240, 240, 240); */
    }
    .hero-banner-right > p{
        text-align: center;
        padding-bottom: 1px;
    }
    .hero-banner-left{
        padding-right: 15px;
        scale:.75;
        /* position: relative;
        right:800px; */

    }
    .hero-banner-left > p{
        font-size: 20px;
        position: relative;
        right:20px;
    }
    span{
        color: #f5a127;
    }
    .hero-btns{
        border: 2px solid rgb(81, 81, 81);
        color: white;
        text-align: center;
        
    }

    .about-gist{
        position: relative;
        width: 500px;
        text-align: center;
        background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(78, 78, 78) 100%);
        color: white;
        padding: 25px;
        border: 5px solid rgb(99, 99, 99);
        left: 10%;
        top: 70px;
        margin-bottom: 35px;
        scale:.85;
    }
    
    .services-gist{
        position: relative;
        width: 500px;
        text-align: center;
        background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(78, 78, 78) 100%);
        color: white;
        padding: 25px;
        border: 5px solid rgb(99, 99, 99);
        left: 10%;
        top: 70px;
        margin-bottom: 35px;
        scale:.85;
    }
    .testimonials-gist{
        position: relative;
        width: 500px;
        text-align: center;
        background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(78, 78, 78) 100%);
        color: white;
        padding: 25px;
        border: 5px solid rgb(99, 99, 99);
        left: 10%;
        top: 70px;
        scale:.85;
    }
    .home-section01{
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        text-align: center;
        align-items: center;
        right:50px;
    }
    .home-info{
        position: relative;
        right: 1px;
        
    }
    .home-logo{
        scale: .75;
    }
   

}

