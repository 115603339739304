@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Cinzel:wght@400..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bad+Script&family=Bebas+Neue&family=Cinzel:wght@400..900&family=Darker+Grotesque:wght@300..900&family=Six+Caps&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bad+Script&family=Bebas+Neue&family=Cinzel:wght@400..900&family=Darker+Grotesque:wght@300..900&family=Medula+One&family=Six+Caps&display=swap');


.navbar {
    background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(78, 78, 78) 100%);
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 999;
    border-bottom: 3px solid black ;
    
  }
  
  .navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    max-width: 1500px;
  }
  
  .navbar-logo {
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
  }
  
  .fa-typo3 {
    margin-left: 0.5rem;
    font-size: 1.8rem;
  }
  
  .nav-menu {
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 60vw;
    justify-content: flex-end;
    margin-right: 2rem;
  }
  
  .nav-item {
    height: 80px;
   
    
  }

  .nav-item-contact{
    position: relative;
    bottom:25px;
    right: 21px;
    
    
  }

  /* .nav-item-contact-mobile{
    display: none;
    

  } */
  
  .nav-links {
    color: #ffffff;
    display: flex;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    align-items:flex-end;
    position: relative;
    font-family: "Bebas Neue", sans-serif;
    font-size: 16pt;
    
  }
  
  .nav-links:hover {
    border-bottom: 4px solid #fff;
    transition: all 0.2s ease-out;
  }
  
  .fa-bars {
    color: #9a9a9a;
  }
  .fa-bars:hover{
    color: #c0c0c0;
  }
  
  .nav-links-mobile {
    display: none;
    
  }
  
  .menu-icon {
    display: none;
  }

  .quote-btn{
    background-color: aqua;
  }
  .logo{
    
    height:3.5rem;
  }

  .get-quote-btn{
    color: #f5a127;
  }

  .dropdown-menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 80px;
    background-color: #333;
    border-radius: 0 0 10px 10px;
    z-index: 9999;
}

.dropdown-link {
    color: #fff;
    padding: 10px 20px;
    text-decoration: none;
    font-size: 16pt;
}

.dropdown-link:hover {
    background-color: #575757;
}




  @media (min-width: 960px){

    .nav-item-contact-mobile{
      display: none;
      
  
    }


    .nav-links {
      color: #f5a127;
      display: flex;
      text-decoration: none;
      padding: 0.5rem 1rem;
      height: 100%;
      align-items:flex-end;
      position: relative;
      top:25px;
      
    }

    .btn{
      color:#f9f9f9;
    }
  }
  
  
  @media screen and (max-width: 960px) {
    .NavbarItems {
      position: relative;
    }
  
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 90vh;
      position: absolute;
      top: 80px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
      
    }
  
    .nav-menu.active {
      background: #242222;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
      
      background: linear-gradient(90deg, rgb(45, 43, 40) 0%, rgb(70, 70, 70) 100%);
      
    }
    
  
    .nav-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
      position: relative;
      bottom: 150px;
      
    }
  
    .nav-links:hover {
      background-color: #fff;
      color: #242424;
      border-radius: 0;
    }
  
    .navbar-logo {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(25%, 50%);
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    .fa-times {
      color: #fff;
      font-size: 2rem;
    }
  
    .nav-links-mobile {
      display: block;
      text-align: center;
      margin: 2rem auto;
      border-radius: 4px;
      width: 80%;
      text-decoration: none;
      font-size: 1.5rem;
      background-color: transparent;
      color: #fff;
      padding: 14px 20px;
      border: 1px solid #fff;
      transition: all 0.3s ease-out;
      position: relative;
      bottom: 150px;
      
    }
  
    .nav-links-mobile:hover {
      background: #ffffff;
      color: #242424;
      transition: 250ms;
    }
    .logo{
      position: relative;
      bottom: 18px;
      height:3.5rem;
      
    }

    .dropdown-menu {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 80px;
      background-color: #333;
      border-radius: 0 0 10px 10px;
      z-index: 9999;
      visibility: hidden;
  }

  .contact-ol{
    visibility: hidden;
  }
    
    
  }