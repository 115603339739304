@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Cinzel:wght@400..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bad+Script&family=Bebas+Neue&family=Cinzel:wght@400..900&family=Darker+Grotesque:wght@300..900&family=Six+Caps&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bad+Script&family=Bebas+Neue&family=Cinzel:wght@400..900&family=Darker+Grotesque:wght@300..900&family=Medula+One&family=Six+Caps&display=swap');


.about-page-container{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0,0,0, .2);
    object-fit:contain;
    background: linear-gradient(90deg, #141414 0%, rgb(78, 78, 78) 100%);
    height: 1700px;
    
}

.about-section-container{
    /* background-color: blueviolet; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    width:40%;
    font-size: larger;
    color: aliceblue;
    margin-top: 0px;
    position: relative;
    top:50px
    
}

.form-section-container{
    background-color: #141414; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 70%;
    width: 500px;
    border: #f5a127 solid 2.5px;
    border-radius: 3%;
    position: sticky;
    /* padding-left: 300px;
    padding-right: 300px; */
}

.form-group{
    background-color: #141414;
    color: #f5a127;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    height:83px;
    width: 450px;
    justify-content: space-between;
    align-items: center;
    /* border: 1.5px solid black; */
    border-radius: 3%;
    padding-top: 3px;
    padding-bottom: 15px;
    font-family: "Darker Grotesque", sans-serif;
    /* font-size: x-large; */

}

.form-group-description{
    background-color: #141414;
    color: #f5a127;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    height:150px;
    width: 450px;
    justify-content: space-between;
    align-items: center;
    /* border: 1.5px solid black; */
    border-radius: 3%;
    padding-top: 3px;
    /* padding-bottom: 15px; */

}

.form-group-btn{
    display: flex;
    justify-content: center;
    margin-top: 50px;
}


h2{
    color: #e8e8e8;
    font-family: "Bebas Neue", sans-serif;
    font-size: 22pt;
    position: relative;
    bottom: 80px;
    margin-top: 0px;
    font-size: 70pt;
}

.h2-form{
    color: white;
    padding-top: 75px;
    font-size: 28pt;
    font-family: "Bebas Neue", sans-serif;
}

span{
    color: #f5a127;
    font-family: "Bebas Neue", sans-serif;
    font-size: 24pt;
    
}

.span-details{
    font-size: x-large;
    font-weight: bold;
    color: #6c8484;
    font-family: "Darker Grotesque", sans-serif;
    font-size: x-large;
    
}

.form-btn{
    background-color: #f5a127;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 85%;
    height:50px;
    font-size: large;
}

.input-box{
    width: 75%;
    font-size: large;
}


#form-description{
    height: 100px;
    width: 80%;
    font-size: large;
    
}

.p-about{
    font-family: "Darker Grotesque", sans-serif;
    font-size: x-large;
    position: relative;
    bottom: 150px;
}
.hide-text{
    visibility: hidden;
}

/* .h2-about{
    color: #f5a127;
} */

@media screen and (max-width: 960px) {
    #root{
        /* height: 300vh; */
        display: flex;
        flex-direction: column;
        width:100%;
        overflow: hidden;
    }

    .about-page-container {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        box-shadow: inset 0 0 0 1000px rgba(0,0,0, .2);
        object-fit:contain;
        background: linear-gradient(90deg, #141414 0%, rgb(78, 78, 78) 100%);
        height: 2000px;
        width: 100%;
        
        /* height: 100px; */
    }

    .about-section-container{
        width: 100%;
        margin: 0px;
        height: 1000px;
        position: relative;
        left: 240px;
        display: flex;
        justify-content: center ; 
        align-items: center;
        align-content: center;
        scale:.95;

       
        /* top: 500px; */

    }

    .form-section-container {
        visibility: hidden;
        position: relative;
        right:200px;
        display: none;
        width: 100%;
        padding: 10px;
    }
    .h2-about{
        margin-bottom: 0;
        padding-bottom: 0;
        position: relative;
        /* top:25px; */
    }
    .p-about{
        font-family: "Darker Grotesque", sans-serif;
        font-size: x-large;
        position: relative;
        bottom: 150px;
    }
}
    
